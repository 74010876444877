<template>
	<div></div>
</template>

<script>
	export default {
		created() {
			const div = document.createElement('div') //创建div
			div.innerHTML = localStorage.getItem('zfb_pay_code') //将返回的form 放入div
			document.body.appendChild(div);
			document.forms[0].submit();
		},
	}
</script>

<style>
</style>
